<template>
	<div class="inner-size">
		<h1>Reset password</h1>
		<form>
			<div class="formgroup">
				<label>Email</label>
				<input v-model="form.email" type="email">
				<span class="error">{{ getFirstError('email') }}</span>
			</div>
			<div class="formgroup">
				<label>New password</label>
				<input v-model="form.password" type="password">
				<span class="error">{{ getFirstError('password') }}</span>

			</div>
			<div class="formgroup">
				<label>Confirm password</label>
				<input v-model="form.password_confirmation " type="password">
				<span class="error">{{ getFirstError('confirm_password') }}</span>
			</div>
			<input type="hidden" name="token" v-model="form.token">
			<input @click="resetPassword" class="button" placeholder="email" type="submit" value="Reset password">
		</form>
	</div>
</template>

<script>
import axios from "axios";

export default {

	name: 'ResetPasswordView',
	data() {
		return {
			form: {
				email: '',
				password: '',
				password_confirmation: '',
				token: '',
			},
			errors: null

		}
	},
	methods: {
		getFirstError(field) {
			if (this.errors !== null && this.errors[field] !== undefined) {
				return this.errors[field][0];
			}
			return null
		},
		resetPassword(e) {
			var self = this;
			e.preventDefault()
			axios.post('https://api.footballguessr.com/reset-password', this.form).then(response => {
				console.log(response);
			}).catch(error => {
				console.log('dit gebeurd');
				console.log(error.response.data)
				self.errors = error.response.data.errors
			});
		}
	},
	computed: {},
	mounted(){
		this.form.token = this.$router.currentRoute._value.query.token
	}

}
</script>
