<template>
    <header>
        <div class="inner-size">
            <router-link to="/" class="logolink">
                <img class="logo" alt="Footballguessr.com logo" v-bind:src='require("@/assets/logo.svg")'>
            </router-link>


            <nav v-if="!$store.getters.user">
                <router-link to="/login">Login</router-link>
                <!--                <router-link to="/register">Register</router-link>-->
            </nav>
            <nav v-if="$store.getters.user">
                <span class="user" v-if="$store.getters.user" @click="logout">Logout</span>
                <router-link to="/stats" class="user streak" v-if="$store.getters.user">{{ $store.getters.user.name }} |
                    {{ streak }} <img class="streak_logo" v-bind:src='require("@/assets/streak.svg")'></router-link>
            </nav>
        </div>

        <nav class="mainmenu">
            <router-link to="/">
                Home
            </router-link>
            <router-link to="/challenge-create">
                Create challenge
            </router-link>
            <router-link to="/about">
                About
            </router-link>
        </nav>
    </header>

    <main>
        <router-view/>
    </main>
    <footer>
        <a href="mailto:contact@footballguessr.com">contact@footballguessr.com</a>
    </footer>
</template>


<script>
import axios from "axios";

export default {
    name: 'App',
    data() {
        return {
            streak: 0
        }
    },
    methods: {
        logout() {
            axios.post('https://api.footballguessr.com/logout', {}).then(response => {
                console.log(response);
                this.$store.commit('user', null)

            }).catch(error => {
                console.log('dit gebeurd');
                console.log(error.response.data)
            });
        },
    },
    computed: {},
    watch: {
        '$store.state.streak': {
            handler() {
                console.log('gebeurd')
                this.streak = this.$store.getters.streak
            }
        }
    },
    mounted() {
        axios.get('https://api.footballguessr.com/sanctum/csrf-cookie');

        axios.get('https://api.footballguessr.com/api/user').then(response => {
            this.$store.commit('user', response.data)
        }).catch((error) => {
            this.$store.commit('user', null)
            console.log(error) // this will log an empty object with an error property
        });

        if (!this.$store.getters.user) {
            //checks if there is a session, maybe some created_at checking in the future?
            if (localStorage.session_uuid) {
                this.$store.commit('uuid', localStorage.session_uuid)
            } else {
                localStorage.firstTime = 1;
            }

            axios
                .get('https://api.footballguessr.com/init-session', {
                    headers: {
                        'SESSION-UUID': this.$store.getters.uuid,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(response => {
                    let data = response.data;

                    localStorage.session_uuid = data.uuid;
                    this.$store.commit('uuid', localStorage.session_uuid)
                });
        }
    },
}
</script>