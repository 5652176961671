<template>
	<div class="inner-size">
		<h1>Forgot password</h1>
		<form v-show="!successmessage">
			<div class="formgroup">
				<label>Email</label>
				<input v-model="form.email" type="email">
				<span class="error">{{ getFirstError('email') }}</span>
			</div>
			<input @click="requestPasswordReset" class="button" placeholder="email" type="submit" value="Reset password">
		</form>

		<span v-show="successmessage" class="success">{{ successmessage }}</span>

	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'ForgotPasswordView',
	data() {
		return {
			form: {
				email: '',
			},
			errors: null,
			successmessage: null

		}
	},
	methods: {
		getFirstError(field) {
			if (this.errors !== null && this.errors[field] !== undefined) {
				return this.errors[field][0];
			}
			return null
		},
		requestPasswordReset(e) {
			var self = this;
			e.preventDefault()
			axios.post('https://api.footballguessr.com/forgot-password', this.form).then(response => {
				console.log(response);
				self.successmessage = response.data.message
				self.errors = null
			}).catch(error => {
				console.log('dit gebeurd');
				console.log(error.response.data)
				self.errors = error.response.data.errors
			});
		}
	},
	computed: {},

}
</script>
