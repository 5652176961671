<template>
    <div class="inner-size">
        <h1>Register</h1>
        <form>
            <div class="formgroup">
                <label>Email</label>
                <input v-model="form.email" type="email">
                <span class="error">{{ getFirstError('email') }}</span>
            </div>
            <div class="formgroup">
                <label>Name</label>
                <input v-model="form.name" type="text">
                <span class="error">{{ getFirstError('name') }}</span>

            </div>
            <div class="formgroup">
                <label>Password</label>
                <input v-model="form.password" type="password">
                <span class="error">{{ getFirstError('password') }}</span>

            </div>
            <div class="formgroup">
                <label>Confirm password</label>
                <input v-model="form.password_confirmation " type="password">
                <span class="error">{{ getFirstError('confirm_password') }}</span>
            </div>
            <input @click="register" class="button" type="submit" value="Register">
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {

    name: 'RegisterView',
    data() {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                errors: null,
                uuid: null
            }

        }
    },
    methods: {
        getFirstError(field){
            if(this.form.errors !== null && this.form.errors[field] !== undefined){
                return this.form.errors[field][0];
            }
            return null
        },
        register(e) {
            var self = this;

            this.form.uuid = this.$store.getters.uuid;

            e.preventDefault()
            axios.post('https://api.footballguessr.com/register', this.form).then(response => {
                console.log(response)
                axios.get('https://api.footballguessr.com/api/user').then(response => {
                    this.$store.commit('user', response.data)
                }).catch((error) => {
                    this.$store.commit('user', null)
                    console.log(error) // this will log an empty object with an error property
                });
                self.$router.push('/')
                self.$router.push('/')

            }).catch(error => {
                console.log('dit gebeurd');
                console.log(error.response.data)
                self.form.errors = error.response.data.errors
            });
        }
    },
    computed: {

    }

}
</script>
