import { createApp } from 'vue'
import { createStore } from 'vuex'

import App from './App.vue'
import "./assets/css/style.css"
import router from './router'
import axios from "axios";


axios.defaults.withCredentials = true;


// Create a new store instance.
const store = createStore({
    state () {
        return {
            user: null,
            uuid: null,
            streak: 0
        }
    },
    mutations: {
        user (state, user) {
            state.user = user;
        },
        streak (state, streak) {
            state.streak = streak;
        },
        uuid (state, uuid) {
            state.uuid = uuid;
        }
    },
    getters: {
        user(state){
            return state.user;
        },
        streak(state){
            return state.streak;
        },
        uuid(state){
            return state.uuid;
        }
    }
})

// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from) => {
//     console.log(to);
//     console.log('user', store.getters.user);
//     if(store.getters.user){
//         if(to.name === 'login'){
//             return false
//         }
//     }
//     return true
// })

createApp(App).use(router).use(store).mount('#app')
