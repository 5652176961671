<template>
	<div class="inner-size">
		<h1>Login</h1>
		<div style="display: block; margin: 20px;">
			Login to keep track of your guessing streaks and other fun statistics!
		</div>
		<form>
			<div class="formgroup">
				<label>Email</label>
				<input v-model="form.email" type="email">
				<span class="error">{{ getFirstError('email') }}</span>
			</div>
			<div class="formgroup">
				<label>Password</label>
				<input v-model="form.password" type="password">
				<span class="error">{{ getFirstError('password') }}</span>
			</div>
			<input @click="register" class="button" type="submit" value="Login">
		</form>

		<div style="display: block; margin-top: 20px;">
			Don't have an account yet? Click
			<router-link to="/register">here</router-link>
			to register!
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {

	name: 'RegisterView',
	data() {
		return {
			form: {
				email: '',
				password: '',
				errors: null
			}

		}
	},
	methods: {
		getFirstError(field) {
			if (this.form.errors !== null && this.form.errors[field] !== undefined) {
				return this.form.errors[field][0];
			}
			return null
		},
		register(e) {
			var self = this;
			e.preventDefault()
			axios.post('https://api.footballguessr.com/login', this.form).then(response => {
				axios.get('https://api.footballguessr.com/api/user').then(response => {
					this.$store.commit('user', response.data)
				}).catch((error) => {
					this.$store.commit('user', null)
					console.log(error) // this will log an empty object with an error property
				});
				self.$router.push('/')
				console.log(response);
			}).catch(error => {
				console.log('dit gebeurd');
				console.log(error.response.data)
				self.form.errors = error.response.data.errors
			});
		}
	},
	computed: {},

}
</script>
