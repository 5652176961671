<template>
    <div class="home inner-size">
        <h1>About us</h1>
        <p>
            Are you a true football aficionado? Put your knowledge to the test with FootballGuessr.com, the premier destination for football fans who love a challenge. Our game is simple yet exhilarating - guess the identities of three football players based on their club affiliations and match histories.
        </p>
    </div>


</template>

<script>
// @ is an alias to /src

export default {
    name: 'AboutView',
    components: {
    }
}
</script>
