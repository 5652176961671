<template>
    <div>
        <h2 v-if="!correct_player">Hints ({{ hints.length }}/{{ totalHints }}):</h2>
        <h2 v-if="correct_player">Career:</h2>

        <ul ref="hintlist" class="hintsgrid">
            <li v-for="hint in hints" v-bind:key="hint.country">
                <div class="goals">
                    <span class="stats"
                          :title="'Appearances: '+ hint.career_part.games + ' - (Goals: ' +  hint.career_part.goals +')'">{{
                            hint.career_part.games
                        }} apps - ({{ hint.career_part.goals }} gls)</span>
                </div>
                <div class="years">
                    <span class="from_date">{{ hint.career_part.date_from }}</span> - <span
                    class="to_date">{{ hint.career_part.date_to ? hint.career_part.date_to : '' }}</span>
                </div>
                <div class="flag">
                    <img :alt="hint.team.country_name+' flag'"
                         v-bind:src='require("@/assets/flags/"+hint.team.transfermarkt_country_id+".svg")'>
                </div>
                <div class="club">
                    {{ hint.career_part.transfer_fee === "BEGIN_LOAN" ? '↳ ' : '' }}{{ hint.team.name }}
                </div>
            </li>
        </ul>

        <search-bar v-model="guess" v-if="!correct_player"></search-bar>


        <div :class="{ 'wrong': failed }" class="dailyplayer" v-if="correct_player">
            <h2 v-if="!failed">You guessed it!</h2>
            <h2 v-if="failed">You didn't guess it :(</h2>
            <div class="player">
                <img :src="correct_player.image_url">
<!--                <span class="correct_answer">{{ correct_player.name }}</span>-->
                <ul class="player_info">
                    <li class="name">{{ correct_player.name }}</li>
                    <li>Country: {{ correct_player.country_of_birth }}</li>
                    <li>Position: {{ correct_player.position }}</li>
                    <li>Birthdate: {{ correct_player.date_of_birth }}</li>
                </ul>
            </div>
            <span class="stats" v-if="avg_guess_count">
                <span>You: {{failed ? '-' :guesses.length}}</span>
                <span>Others: {{avg_guess_count ? Math.round(avg_guess_count,2) : ''}}</span>

            </span>
        </div>

		<div class="copy-result" v-if="correct_player">
			<a v-on:click="$emit('share_click', $event)" rel="nofollow">Share result</a>
		</div>

        <div class="after-options" v-if="correct_player && roundNumber === 0 && !moreRounds">
            <a @click="nextRound" rel="nofollow">Want to play another round?</a>
        </div>

        <a class="button skipbtn" v-if="!correct_player" @click="skipGuess" rel="nofollow">Skip guess</a>

        <h2>Guesses ({{ guesses.length }}/{{ maxGuesses }}) :</h2>

        <ul class="wrong_guesses">
            <li v-for="(wrongguess, key) in guesses" v-bind:key="key">
                {{ wrongguess !== null ? wrongguess.name : '-' }}
            </li>
        </ul>
    </div>
</template>

<script>
import axios from "axios";
import SearchBar from "@/components/SearchBar";


export default {
    name: 'FootballGuessr',
    components: {SearchBar},
    props: ['roundNumber', 'moreRounds', 'challengeHash'],
    data() {
        return {
            timeoutQuery: null,
            input: '',
            active: false,
            highlightedIndex: 0,
            guess: '',
            avg_guess_count: null,

            suggestedPlayers: [],
            guesses: [],
            maxGuesses: 0,
            hints: [],
            totalHints: 0,
            correct_player: null,
            failed: false,

            uuid: ''
        }
    },
    watch: {
        '$store.state.uuid': {
            handler() {
                if (this.$store.getters.uuid) {
                    this.uuid = this.$store.getters.uuid;
                    this.loadRound();
                }

            }
        },
        '$store.state.user': {
            handler() {
                this.loadRound();
            }
        },
        correct_player: {
            handler() {
                this.returnRoundState();
            }
        },
        hints: {
            handler() {
                // var self = this;
                // this.$nextTick(() => {
                //     window.requestAnimationFrame(() => {
                //         self.$refs.hintlist.scollToBottom = self.$refs.hintlist.scrollHeight;
                //         console.log('nexttick')
                //     })
                // });
                //
                var self = this;
                setTimeout(function () {
                    self.$refs.hintlist.scrollTop = self.$refs.hintlist.scrollHeight;
                }, 100)

            },
            deep: true,
        },
        guess(guess) {
            if (guess !== '') {
                this.submitGuess(guess)
            }
        }
    },
    methods: {
        nextRound() {
            this.$emit('moreRounds', true)
        },
        returnRoundState() {
            //COMPLETED,FAILED,ONGOING
            if (!this.correct_player) {
                this.$emit('roundStatusUpdate', {roundNumber: this.roundNumber, status: "ONGOING"})
            } else {
                if (this.failed) {
                    this.$emit('roundStatusUpdate', {roundNumber: this.roundNumber, status: "FAILED"})
                } else {
                    this.$emit('roundStatusUpdate', {roundNumber: this.roundNumber, status: "COMPLETED"})

                }
            }
        },
        skipGuess() {
            this.submitGuess('');
        },
        submitGuess(guess) {
            var url = 'https://api.footballguessr.com/guess'

            if (this.challengeHash) {
                url = 'https://api.footballguessr.com/guess-challenge'
            }

            axios
                .post(url, {
                    player: guess,
                    round_number: (this.roundNumber + 1),
                    challenge_hash: this.challengeHash
                }, {
                    headers: {
                        'SESSION-UUID': this.uuid
                    }
                })
                .then(response => {

                    var data = response.data

                    this.hints = data.hints.data;
                    this.totalHints = data.hints.total;

                    this.guesses = data.guesses;
                    this.avg_guess_count = data.avg_guess_count;

                    this.$emit('updateGuessCount', {roundNumber: this.roundNumber, guesses: this.guesses.length})


                    if (data.correct_player !== undefined) {
                        this.correct_player = data.correct_player;
                    }


                    if (data.streak !== undefined) {
                        this.$store.commit('streak', data.streak)
                    }

                    this.failed = data.failed

                    this.guess = '';
                    this.input = '';
                });

        },
        pickOption(option) {
            this.active = false;
            this.guess = option.name;
            this.input = option.name;
        },
        onEnter: function () {
            if (this.suggestedPlayers[this.highlightedIndex] !== undefined) {
                this.pickOption(this.suggestedPlayers[this.highlightedIndex]);
            }
        },
        loadRound() {
            var url = 'https://api.footballguessr.com/round/' + (this.roundNumber + 1);

            if (this.challengeHash) {
                url = 'https://api.footballguessr.com/challenge/' + this.challengeHash;
            }

            axios
                .get(url, {
                    headers: {
                        'SESSION-UUID': this.uuid,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(response => {
                    let data = response.data;

                    this.hints = data.hints.data;
                    this.totalHints = data.hints.total;
                    this.guesses = data.guesses;
                    this.avg_guess_count = data.avg_guess_count;

                    if (this.guesses.length > 0) {
                        if (this.roundNumber === 1) {
                            this.$emit('moreRounds', true)
                        }
                        this.$emit('updateGuessCount', {roundNumber: this.roundNumber, guesses: this.guesses.length})
                    }

                    this.maxGuesses = data.max_guesses;
                    this.failed = data.failed

                    if (data.correct_player !== undefined) {
                        this.correct_player = data.correct_player;
                    }
                });

            this.returnRoundState();

        }
    },

    mounted() {
        if (this.$store.getters.uuid) {
            console.log('started loading in mounted')
            this.uuid = this.$store.getters.uuid;
            this.loadRound();
        }
    }
}
</script>
