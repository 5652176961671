<template>
    <div class="home">
        <div class="copy_notification" :class="{show: showCopyPopup}">Your result has been copied to your clipboard!
        </div>

        <ul class="round_tabs">
            <li @click="copyResultToClipboard" class="share">
                <img alt="share" class="shareimg" v-bind:src='require("@/assets/share.svg")'/>
            </li>
        </ul>

        <football-guessr v-if="challenge" :challenge-hash="challenge"  :uuid="uuid" @update-guess-count="updateGuessCount" @round-status-update="changeRoundState"/>
    </div>
</template>

<script>
// @ is an alias to /src
import FootballGuessr from '@/components/FootballGuessr.vue'
import axios from "axios";

export default {
    name: 'ChallengeView',
    components: {
        FootballGuessr,
    },
    data() {
        return {
            challenge: false,
            showCopyPopup: false,
            uuid: '',
            rounds: [],
            guessCount: 0,
            status: ''


        }
    },
    methods: {
        copyResultToClipboard() {

            let result = "My Footballguessr result:\n\n";


            result += "1️⃣";


            for (let j = 0; j < this.guessCount; j++) {
                if (j === this.guessCount - 1) {
                    if (this.status === 'COMPLETED') {
                        result += "🟩";
                    } else {
                        result += "🟥";
                    }
                } else {
                    result += "⬛";
                }
            }

            result += "\n";


            result += "\nGive it a shot on footballguessr.com";

            navigator.clipboard.writeText(result);

            this.showCopyPopup = true;
            setTimeout(() => this.showCopyPopup = false, 1500)


        },
        updateGuessCount(resp) {
            console.log(resp);
            this.guessCount = resp.guesses;
        },
        changeRoundState(resp) {
            this.status = resp.status;
        },
        loadGame() {
            this.uuid = this.$store.getters.uuid;

            if (localStorage.firstTime && parseInt(localStorage.firstTime) === 1) {
                this.showExplainer = true;
                localStorage.firstTime = 0;
            }

            axios
                .get('https://api.footballguessr.com/init-challenge/'+this.$route.params.hash, {
                    headers: {
                        'SESSION-UUID': this.uuid,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(response => {
                    let data = response.data;
                    this.$store.commit('streak', data.streak)

                    if(data.status === 200){
                        this.challenge = this.$route.params.hash;
                    }
                });
        }
    },
    watch: {
    },
    mounted() {
        this.loadGame();
    }
}
</script>
