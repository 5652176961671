<template>
    <div class="home">
        <div class="explainer" :class="{show: showExplainer}">
            <span class="close" @click="showExplainer = false"></span>
            <h2>Welcome to FootballGuessr!</h2>
            <p>
                The goal of the game is to guess a football (a.k.a. soccer) player based on his career history.
            </p>
            <p>
                You start with one hint. Guess which player it is by typing his name in the search bar and selecting the
                player. If you guessed wrong, another hint will be revealed to you, until there are no more hints
                possible. You get a maximum of 10 guesses. If you guess right, great! Your friends will be proud of you
                😉
            </p>
            <p>
                There are 3 rounds of increasing difficulty. Try them all and share your results with your friends using
                the share button.
            </p>
            <p>
                Come back the next day for a new set of players!
            </p>
            <p>Player careers updated on 2024-02-07</p>
        </div>
        <div class="copy_notification" :class="{show: showCopyPopup}">Your result has been copied to your clipboard!
        </div>

        <ul class="round_tabs">
            <li :class="{active: active_round === roundNumber, failed: roundInfo.status === 'FAILED', completed: roundInfo.status === 'COMPLETED'}"
                v-for="(roundInfo, roundNumber) in rounds" :key="roundNumber" v-show="more_rounds"
                @click="active_round = roundNumber">Round {{ roundNumber + 1 }}
            </li>
            <li @click="showExplainer = true" class="share">
                <img alt="help" class="shareimg" v-bind:src='require("@/assets/help.svg")'/>
            </li>
        </ul>
        <football-guessr @update-guess-count="updateGuessCount" @round-status-update="changeRoundState" v-on:share_click="copyResultToClipboard"
                         v-show="active_round === roundNumber"
                         v-for="(i, roundNumber) in rounds" :key="roundNumber" :round-number="roundNumber"
                         :uuid="uuid" @more-rounds="more_rounds = $event; active_round = 1" :more-rounds="more_rounds"/>

        <div class="game_explanation inner-size">
            <h1>Footballguessr, daily football guessing game</h1>
            <p class="">
                Test your football knowledge at FootballGuessr.com! Guess three football players based on club and match
                history. Create your challenges and challenge friends. Are you up for the ultimate football guessing
                challenge?
            </p>
            <p>
                The goal of the game is to guess a football (a.k.a. soccer) player based on his career history.
            </p>
            <p>
                You start with one hint. Guess which player it is by typing his name in the search bar and selecting the
                player. If you guessed wrong, another hint will be revealed to you, until there are no more hints
                possible. You get a maximum of 10 guesses. If you guess right, great! Your friends will be proud of you
                😉
            </p>
            <p>
                There are 3 rounds of increasing difficulty. Try them all and share your results with your friends using
                the share button.
            </p>
            <p>
                Come back the next day for a new set of players!
            </p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import FootballGuessr from '@/components/FootballGuessr.vue'
import axios from "axios";

export default {
    name: 'HomeView',
    components: {
        FootballGuessr,
    },
    data() {
        return {
            showCopyPopup: false,
            showExplainer: false,
            uuid: '',
            rounds: [],
            active_round: 0,
            more_rounds: false

        }
    },
    methods: {
        copyResultToClipboard() {

            let result = "My Footballguessr result:\n\n";

            for (var i = 0; i < this.rounds.length; i++) {
				if(this.rounds[i].guessCount > 0) {
					if (i > 0 && !this.more_rounds) {
						continue;
					}
					if (i === 0) {
						result += "1️⃣";
					} else if (i === 1) {
						result += "2️⃣";
					} else if (i === 2) {
						result += "3️⃣";
					}

					for (let j = 0; j < this.rounds[i].guessCount; j++) {
						if (j === this.rounds[i].guessCount - 1) {
							if (this.rounds[i].status === 'COMPLETED') {
								result += "🟩";
							} else {
								result += "🟥";
							}
						} else {
							result += "⬛";
						}
					}

					result += "\n";
				}
            }

            result += "\nGive it a shot on footballguessr.com";

            navigator.clipboard.writeText(result);

            this.showCopyPopup = true;
            setTimeout(() => this.showCopyPopup = false, 1500)


        },
        updateGuessCount(resp) {
            this.rounds[resp.roundNumber].guessCount = resp.guesses;
        },
        changeRoundState(resp) {
            this.rounds[resp.roundNumber].status = resp.status;
        },
        loadGame() {
            this.uuid = this.$store.getters.uuid;

            console.log(localStorage.firstTime)
            console.log(localStorage.firstTime === 1)

            if (localStorage.firstTime && parseInt(localStorage.firstTime) === 1) {
                this.showExplainer = true;
                localStorage.firstTime = 0;
            }

            axios
                .get('https://api.footballguessr.com/init', {
                    headers: {
                        'SESSION-UUID': this.uuid,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(response => {
                    let data = response.data;

                    this.$store.commit('streak', data.streak)

                    for (let i = 0; i < data.rounds; i++) {
                        this.rounds.push({status: null, guessCount: 0});
                    }
                });
        }
    },
    watch: {
        // '$store.getters.uuid': {
        //     handler() {
        //         console.log('watcher activated')
        //         console.log(this.$store.state.uuid)
        //
        //     }
        // }
    },
    mounted() {
        this.loadGame();
    }
}
</script>
