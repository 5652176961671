<template>
    <div class="about inner-size">
        <h1>Create challenge</h1>

        <search-bar v-model="player" v-if="!hash"></search-bar>

        <div v-if="player && !hash">
            <p>Player: {{player}}</p>
            <a @click="createChallenge" class="button">Create challenge</a>
        </div>

        <div v-if="hash">
            Share this link with your friends: <br/><br/><a target="_blank" :href="'/challenge/'+hash">https://footballguessr.com/challenge/{{hash}}</a>
            <br/><br/>
            <a class="button" @click="copylink">Copy link</a>
        </div>

    </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import axios from "axios";

export default {
    name: 'FootballGuessr',
    components: {SearchBar},
    props: ['roundNumber', 'moreRounds'],
    data() {
        return {
            player: '',
            hash: ''
        }
    },
    methods: {
        copylink(){
            let result = "Try my Footballguessr challenge:\n\n https://footballguessr.com/challenge/"+this.hash;
            navigator.clipboard.writeText(result);
        },
        createChallenge() {
            let player = this.player;

            let self = this;

            axios
                .post('https://api.footballguessr.com/create-challenge', {
                    player: player,
                }, {
                    headers: {
                        'SESSION-UUID': this.uuid
                    }
                })
                .then(response => {

                    var data = response.data

                    if(data.challenge_hash !== undefined){
                        self.hash = data.challenge_hash
                    }

                });

        },
    }
}
</script>

