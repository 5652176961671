<template>
	<div class="home">
		<div class="statswrapper">

		<h1>Stats</h1>
			<ul>
				<li v-for="(day, date) in dayStats" v-bind:key="date">
					<h2>{{date}}</h2>
					<table>
						<tr v-for="(round, i) in day" v-bind:key="i">
							<td>Round {{round.round}}</td>
							<td>{{round.n_guesses}} guesses</td>
							<td><span class="player" :class="{correct: round.guessed_correctly === 1}">{{round.correct_player_name}}</span></td>
							<td></td>
						</tr>
					</table>
				</li>
			</ul>
		</div>
	</div>
</template>

<style>
.statswrapper{
	text-align: left;
}
table{
	width: 100%;
	text-align: left;
}
table td{
	width: 25%;
	text-align: left;
}

table td .player{
	color: #bd1e1e;
}
table td .player.correct{
	color: #2bc156;
}


</style>

<script>
// @ is an alias to /src
// eslint-disable-next-line
import axios from "axios";

export default {
	name: 'StatsView',

	data() {
		return {
			dayStats : []
		}
	},
	methods: {},
	watch: {},
	mounted() {
		let self = this;
		axios.get('https://api.footballguessr.com/stats').then(response => {

			if(response.data.error !== undefined) {
				self.dayStats = []
			}else{
				self.dayStats = response.data;

			}

			console.log(self.dayStats)

		}).catch((error) => {
			this.$store.commit('user', null)
			console.log(error) // this will log an empty object with an error property
		});
	}
}
</script>
