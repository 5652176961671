<template>
	<div class="search-bar">
		<input class="guess_input" ref="playerSearch" name="skjdhadkjsfhdsakfj" type="text" @focus="active = true" @blur="blur" @input='evt=>this.input=evt.target.value' v-on:keyup.enter="onEnter" v-on:keyup.down="onDown" v-on:keyup.up="onUp" placeholder="search player..." autocomplete="off"/>
		<div v-if="active" class="option-modal">
			<ul class="items">
				<li :class="{ active: index === highlightedIndex }" v-bind:key="player.id"
					v-for="(player,index) in suggestedPlayers" @mouseenter="highlightedIndex = index"
					v-on:mousedown="pickOption(player.name)">
					<img class="country_flag" v-bind:src='imageSrc(player.country_id_of_citizenship)'>
					{{ player.name }}
				</li>
			</ul>
			<span v-if="totalOptions - suggestedPlayers.length > 0" class="total_players">... and {{totalOptions-suggestedPlayers.length}} more</span>
        </div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	props: ['modelValue'],
	emits: ['update:modelValue'],
	watch: {
		input(newstr) {
			if (newstr.length > 0) {
				if (this.timeoutQuery) {
					clearTimeout(this.timeoutQuery)
				}
				this.timeoutQuery = setTimeout(() => this.search(newstr), 250)

			} else {
				this.suggestedPlayers = [];
			}
		},
	},
	data: function () {
		return {
			input: '',
			active: false,
			highlightedIndex: null,
			suggestedPlayers: [],
			totalOptions: null,
            request: 0
		};
	},
	computed: {
		settings() {
			return this.$attrs.settings
		}
	},
	methods: {
		imageSrc (country_id) {
			try {
				return require("@/assets/flags/"+country_id+".svg")
			} catch (e) {
				return require("@/assets/flags/3000.svg")
			}
		},
		search(query) {
			let self = this;
            this.request++;
            let currentRequest = this.request;

			axios
				.get('https://api.footballguessr.com/search_player/' + query)
				.then(response => {
                    if(currentRequest === self.request) {
                        self.suggestedPlayers = response.data.data;
                        self.totalOptions = response.data.total;
                    }
				});
		},
		pickOption(option) {
			this.active = false;
			this.$emit('update:modelValue', option)
            this.totalOptions = 0;
			this.input = '';
            this.$refs.playerSearch.value = '';
		},
		onEnter: function () {

			if (this.highlightedIndex && this.suggestedPlayers[this.highlightedIndex] !== undefined) {
				this.pickOption(this.suggestedPlayers[this.highlightedIndex]);
			}
		},
		onUp: function(){
			if(this.highlightedIndex !== null && this.highlightedIndex > 0){
				this.highlightedIndex --
			}
		},
		onDown: function(){
			if(this.highlightedIndex !== null){
				this.highlightedIndex ++
			}else{
				this.highlightedIndex = 0;
			}
		},
		blur() {
			this.active = false;
			this.highlightedIndex = null;
		},
	},
	mounted() {
	}
}
</script>